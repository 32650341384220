



























































import { mapActions } from 'vuex';
import { NotificationMixin } from '../mixins';
import { Component, Mixins } from 'vue-property-decorator';

@Component({
  name: 'Login',
  methods: {
    ...mapActions('auth', ['login']),
  },
})
export default class Login extends Mixins(NotificationMixin) {
  user = {};
  login!: (user) => Promise<void>;

  submit() {
    // Send data to the server or update your stores and such.
    this.login(this.user)
      .then(() => {
        this.notifySuccess('Welcome back!');
        this.$router.push({ name: 'dashboard' });
      })
      .catch((err) => {
        const errMessage =
          err.message || 'Unrecognised email address or password';
        this.notifyError(errMessage);
      });
  }
}
